import React, {useEffect, useState} from 'react';
import './button/Button.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Home from "./components/Home";
import NotFound from "./components/NotFound";
//import Login from "./login/Login";

function App() {
    //server();

    useEffect(() => {
        const handleContextMenu = (e: MouseEvent) => {
            e.preventDefault()
        }

        document.addEventListener("contextmenu", handleContextMenu)

        return () => {
            document.removeEventListener("contextmenu", handleContextMenu)
        }
    }, [])

    /*
    const [token, setToken] = useState();

    if (!token) {
        return <Login setToken={setToken} />
    }*/

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path='*' element={<NotFound />}/>
                </Routes>
            </BrowserRouter>
        </>
    );
}


export default App;
